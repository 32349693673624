@font-face {
  font-family: 'AvantGardEF';
  src: url('AvantGardEF-Bold.woff2') format('woff2'),
    url('AvantGardEF-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'AvantGardEF';
  src: url('AvantGardEF-Medium.woff2') format('woff2'),
    url('AvantGardEF-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: block;
}
